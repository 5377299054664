import React from 'react';
import MainLayout from '../layouts/mainLayout';

export default () => {
  return (
    <MainLayout withTitle={true}>
      <div className="features-wrapper mgn-sm-top-100">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h4>Delivery</h4>

                    <p className="para-txt mgn-bot-20">
                      We deliver nationwide throughout New Zealand including
                      rural addresses, using 3rd Party courier services (usually
                      NZ Post/ Courier Post). We ship orders on Monday-Friday
                      using an express overnight service (North Island only).
                      South Island orders are delivered using a 2-3 day Service.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      Please allow 2-3 business days for orders in the South
                      Island or Rural addresses. There is a small additional
                      cost for rural addresses that will be added at checkout.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      Once items have left the warehouse we are no longer liable
                      for the delivery success of these orders. We are unable to
                      control the delivery and delays once they have been
                      accepted into NZ Post’s system.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      You will be provided with tracking details for your order.
                      The fastest way to get updates, make changes or resolve
                      issues with your order will be to contact Courier Post
                      directly on 0800 268743 and quote your tracking reference.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      If no one is home at the address the Courier will leave
                      your item in a safe place. If you cannot find your item,
                      please check around your property and with neighbours, or
                      contact Courier Post. Please see courier post service
                      details for more details{' '}
                      <a href="https://support.courierpost.co.nz/">
                        https://support.courierpost.co.nz/
                      </a>
                    </p>
                    <p className="para-txt mgn-bot-20">
                      If you need our help or would like to discuss any issues
                      with your delivery, please contact us and we would be
                      delighted to help you.
                    </p>
                    <h4>Refund policy</h4>
                    <p>
                      We have a 30-day return policy, which means you have 30
                      days after receiving your item to request a return.
                      <br />
                      You are entitled to a refund, replacement or Feed My
                      Furbaby credit if a product:
                      <ul>
                        <li>Is faulty or of unacceptable quality,</li>
                        <li> Does not match the description or sample,</li>
                        <li> Is unfit for the intended purpose,</li>
                        <li> Is not delivered in a reasonable time frame.</li>
                      </ul>
                    </p>
                    <p className="para-txt mgn-bot-20">
                      No refund for repeat subscription orders if you change
                      your mind. You will receive an email notification 3 days
                      prior to your subscription order being processed, to
                      remind you of your upcoming order and give you time to
                      make changes, pause or cancel your orders before they
                      process. Once an order is processed it is not possible to
                      be cancelled or refunded. However, we don’t want you
                      feeling caught out by a repeat order so please contact us
                      to see if there is anything we can do to help. We’re a
                      very accommodating bunch.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      As most of our products are food items we are generally
                      not able to resell returned items so we may request that
                      you donate any unused products to an animal shelter rather
                      than returning to us. In some cases you may be asked to
                      provide photographic evidence rather than returning and
                      this may form part of the refund and returns process.
                    </p>

                    <p className="para-txt mgn-bot-20">
                      To start a return, you can contact us at{' '}
                      <a href="mailto:info@feedmyfurbaby.co.nz">
                        info@feedmyfurbaby.co.nz
                      </a>{' '}
                      If your return is accepted, we’ll send you a return
                      shipping label, as well as instructions on how and where
                      to send your package. Items sent back to us without first
                      requesting a return will not be accepted.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      You can always contact us for any return question at{' '}
                      <a href="mailto:info@feedmyfurbaby.co.nz">
                        info@feedmyfurbaby.co.nz
                      </a>
                    </p>
                    <h4>Damages and issues </h4>
                    <p className="para-txt mgn-bot-20">
                      Please inspect your order upon reception and contact us
                      immediately if the item is defective, damaged or if you
                      receive the wrong item, so that we can evaluate the issue
                      and make it right.
                    </p>
                    <h4>Refund Processing</h4>
                    <p className="para-txt mgn-bot-20">
                      We will notify you once we’ve received and inspected your
                      return, and let you know if the refund was approved or
                      not. If approved, you’ll be automatically refunded on your
                      original payment method. Refunds are processed the same
                      day as they are approved. You will receive a digital
                      receipt from us as soon as your refund is processed -
                      please allow Please remember it can take up to 10 working
                      days for our payment portal, and/ or your bank or credit
                      card company to process and clear the funds in your
                      account. If you have not received your funds within 10
                      working days, please contact our support.
                    </p>
                    <h4>Contacting Us</h4>
                    <p>
                      If there are any questions regarding this privacy policy,
                      you may contact us using the information below:
                    </p>
                    <p>
                      <a href="https://feedmyfurbaby.co.nz/">
                        https://feedmyfurbaby.co.nz/
                      </a>
                    </p>
                    <p>PO Box 11133 Ellerslie,</p>
                    <p>Auckland 1542,</p>
                    <p>New Zealand</p>
                    <p>
                      <a href="mailto:info@feedmyfurbaby.co.nz">
                        info@feedmyfurbaby.co.nz
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
